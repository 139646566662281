import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import api from "@/api/users";

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  state: {
    user: {},
    client: {},
    token: localStorage.getItem("user-token") || "",
    collecteCollectibles: [],
    dateCreneau: '',
    indexPlanning: 0,
    selectedCreneau:{},
    Tarifs:{},
    fromUpdatePanier: false,
    // AsUser:{nom:'',id_utilisateur:''},
    // currentTab: "FICHE CLIENT",
    // entreprise : {
    //     raison_sociale:"",
    //     actif:true,
    //     code_fta:""
    // }
  },
  getters: {
    getCollecteCollectibles: (state) => {
      return state.collecteCollectibles;
    },
  },
  mutations: {
    infosUser: (state, resp) => {
      console.log("mutation", resp);
      state.status = "logged";
      // enregistrement du user dans le state
      state.user = resp.user;
      console.log(state.user, "state mutation user");
      // stocker dans le local storage le token
      localStorage.setItem("user-token", resp.token);
      axios.defaults.headers.common = { Authorization: `Bearer ${resp.token}` };
      console.log("Headers Authorization : ", axios.defaults.headers.common);
    },

    infosClient: (state, resp) => {
      console.log(resp);
      state.client = resp;
      console.log("Client state: ", state.client);
    },

    addCollectibles: (state, resp) => {
      console.log('mutationn', resp);
      state.collecteCollectibles.push(resp);
    },

    dateCreneau: (state, resp) => {
      console.log('mutationn', resp);
      state.dateCreneau = resp
    },

    indexPlanning: (state, resp) => {
      console.log('mutationn', resp);
      state.indexPlanning = resp
    },

    deleteCollectible: (state, resp) => {
      state.collecteCollectibles.splice(resp);
    },

    resetState: (state) => {
      state.collecteCollectibles = [];
      state.client = {};
      state.user = {};
    },
    resetCollecte: (state) => {
      state.collecteCollectibles = [];
    },
    updateCollectible: (state, resp) => {
      console.log(resp);
      // state.collecteCollectibles[resp.index].quantity = resp.quantity
      Vue.set(
        state.collecteCollectibles[resp.index],
        "quantity",
        resp.quantity
      );

      state.collecteCollectibles.push("dog-nail");
      state.collecteCollectibles.splice(-1, 1);
    },
    deleteElementCart: (state, resp) => {
      state.collecteCollectibles.splice(resp, 1);
    },
    fillCart: (state, resp) => {
      state.collecteCollectibles = resp
    },
    creneauSelected: (state, resp) => {
      state.selectedCreneau = resp
    },
    isFromUpdatePanier: (state, resp) => {
      state.fromUpdatePanier = resp
    },
    tarifCollecte: (state, resp) => {
      state.Tarifs = resp
    },
    // connectedAs(state,user){
    // state.AsUser = user

    // },
    // changeOnglet(state,value){
    // state.currentTab = value;
    // },
    // upadateActifEntreprise(state,value){
    // state.entreprise.actif = value
    // },
    // upadateCodeFtaEntreprise(state,value){
    // state.entreprise.code_fta = value
    // }
  },
  actions: {
    isAuthenticated: ({ commit }) => {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("user-token")}`,
        };
        api.isAuthenticated().then((res) => {
          if (res.status == 200) {
            commit("infosUser", res.data.data);
          }
          resolve(res)
        }, error => {
          // http failed, let the calling function know that action did not work out
          reject(error)
        });
      })

    },

  },
});

// on peux supprimer pour moi les dossiers actions,modules et creer un dossier api dans src et y mettre le fichier situé dans utils api.js
